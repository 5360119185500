import { Container } from "@components/crud/Container";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { InsightSummaryBox } from "@components/InsightSummaryBox";
import { FormSelect } from "@components/FormSelect";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { useAdminReleaseAllGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { getQaMetric } from "@services/Network";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { CustomColumnGraph } from "../../components/CustomColumnGraph";
import { TestcaseBreakdown } from "./TestcaseBreakdown";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";

export const QATesting = () => {
  const [releaseId, setReleaseId] = useState<string | undefined>(undefined);
  const [testerId, setTesterId] = useState<string | undefined>("");
  const [platfrom, setPlatform] = useState<string | undefined>("MOB");
  const [featureFilter, setFeatureFilter] = useState<string>("");
  const [featureBreakdown, setFeatureBreakdown] = useState({});
  const [isFeatureBreakdownExpanded, setIsFeatureBreakdownExpanded] =
    useState<boolean>(true);
  const [showFailedBreakdown, setShowFailedBreakdown] =
    useState<boolean>(false);
  const [showBlockedBreakdown, setShowBlockedBreakdown] =
    useState<boolean>(false);

  const { data: releases, isLoading: isLoading } = useAdminReleaseAllGet();
  const releasesOptions = useMemo(
    () =>
      (releases?.data.releases &&
        releases?.data?.releases.map((release) => ({
          label: release.name!,
          value: release.releaseId
        }))) ||
      [],
    [releases]
  );
  const testerOptions = useMemo(() => {
    const testersFromRelease =
      releases?.data.releases &&
      releases.data.releases
        .find((r) => r.releaseId === releaseId)
        ?.testers.map((tester) => ({
          label: `${tester.person.firstName} ${tester.person.lastName}`,
          value: tester.person.userId
        }));

    const options = testersFromRelease
      ? [{ label: "All Testers", value: "" }, ...testersFromRelease]
      : [];

    return options;
  }, [releaseId]);

  const platformOptions: { label: string; value: string }[] = useMemo(() => {
    if (releases?.data.releases && releaseId) {
      const release = releases.data.releases.find(
        (r) => r.releaseId === releaseId
      );
      if (release) {
        let platforms = Array.from(new Set(release.platforms));
        if (platforms.includes("AND") || platforms.includes("IOS")) {
          platforms.push("MOB");
        }
        platforms = platforms.map((platform) => ({
          label: platform,
          value: platform
        }));
        platforms.sort((a, b) => {
          const order = ["MOB", "IOS", "AND", "WEB"];
          return order.indexOf(a.value) - order.indexOf(b.value);
        });
        return platforms;
      }
    }
    return [];
  }, [releaseId]);

  const columns = [
    {
      title: "TC Not Tested",
      color: "#E5E5E5"
    },
    {
      title: "TC Passed",
      color: "#1ABC9C"
    },
    {
      title: "TC Failed",
      color: "#E82C2C"
    },
    {
      title: "TC Onhold",
      color: "#FF710A"
    },
    {
      title: "TC Blocked",
      color: "#F48D8D"
    }
  ];
  useEffect(() => {
    if (!releaseId) setReleaseId(releasesOptions[0]?.value);
    if (platformOptions.length == 1) setPlatform(platformOptions[0]?.value);
    if (!platformOptions.some((option) => option.value === "MOB"))
      setPlatform(platformOptions[0]?.value);
    if (platformOptions.some((option) => option.value === "MOB"))
      setPlatform("MOB");
  }, [platformOptions, releasesOptions]);

  const handleInsightBoxClick = (type: "FAILED" | "BLOCKED") => {
    if (type === "FAILED") {
      setShowFailedBreakdown(!showFailedBreakdown);
    } else if (type === "BLOCKED") {
      setShowBlockedBreakdown(!showBlockedBreakdown);
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <Container>
        <Grid container spacing="15px" direction="column">
          <Grid
            item
            marginLeft="40px"
            marginTop="16px"
            style={{ maxWidth: "500px" }}
          >
            <FormSelect
              name="filter"
              options={[{ label: "QA & Testing Insights", value: "QA" }]}
            />
          </Grid>
          <Grid container paddingLeft="55px" marginTop="16px" columnSpacing={2}>
            <Grid item xs={1}>
              <FormSelect
                isReleaseSelect={true}
                name="releaseFilter"
                onChange={(e) => {
                  setReleaseId(e.target.value);
                }}
                options={releasesOptions}
              />
            </Grid>
            <Grid item xs={1}>
              <FormSelect
                name="testerFilter"
                options={testerOptions}
                onChange={(e) => {
                  setTesterId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <FormSelect
                name="platformFilter"
                options={platformOptions}
                onChange={(e) => {
                  setPlatform(e.target.value);
                }}
              />
            </Grid>
            <Grid item paddingRight="40px" xs={9}>
              <FilterTreeSearchComponent
                onFilterChange={(featureId) => setFeatureFilter(featureId)}
                onClearFilter={() => setFeatureFilter("")}
                release={releaseId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginLeft="25px" padding="15px" marginRight="25px">
          <InsightSummaryBox
            title="QA & Testing Insights"
            description="Quick Insights related to SportGravy Product Releases"
            isLoading={true}
            releaseId={releaseId}
            testerId={testerId}
            platform={platfrom}
            featureId={featureFilter}
            useGet={getQaMetric}
            setFeatureBreakDown={setFeatureBreakdown}
            isQaInsights={true}
            handleInsightClick={handleInsightBoxClick}
          />
        </Grid>

        <TestcaseBreakdown
          type="FAILED"
          releaseId={releaseId as string}
          platform={platfrom as "MOB" | "AND" | "IOS" | "WEB"}
          isDefualtExpanded={showFailedBreakdown}
        />
        <TestcaseBreakdown
          type="BLOCKED"
          releaseId={releaseId as string}
          platform={platfrom as "MOB" | "AND" | "IOS" | "WEB"}
          isDefualtExpanded={showBlockedBreakdown}
        />

        <Grid container direction="column" marginTop="32px" marginBottom="25px">
          <div
            style={{
              display: "flex",
              paddingLeft: "40px",
              alignItems: "center"
            }}
          >
            <IconButton
              onClick={() => {
                setIsFeatureBreakdownExpanded(!isFeatureBreakdownExpanded);
              }}
            >
              {isFeatureBreakdownExpanded ? (
                <IndeterminateCheckBox color="primary" />
              ) : (
                <AddBox color="primary" />
              )}
            </IconButton>
            <Typography style={{ fontWeight: 700, fontSize: "16px" }}>
              Features & Test Cases Breakdown
            </Typography>
          </div>
          <HeaderUnderLine marginLeft="20px" />
          {isFeatureBreakdownExpanded && (
            <CustomColumnGraph columns={columns} values={featureBreakdown} />
          )}
        </Grid>
      </Container>
    </Loader>
  );
};
