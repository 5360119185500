/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

export interface Column {
  field: string;
  headerName?: string;
  width?: number;
  borderLeft?: string;
  borderRight?: string;
  align?: "right" | "center" | "left";
  renderCell?: (value: any) => JSX.Element;
  renderHeader?: () => JSX.Element;
  onClick?: (row: any) => void;
  sortable?: boolean;
}

export interface ColumnGrouping {
  label: string;
  pinned?: boolean;
  width?: number;
  align?: "left" | "center";
  columns: string[];
  border?: string;
}

interface TotalValue {
  field: string;
  renderCell: (rows: any[]) => JSX.Element;
}

export const DataGridTable = ({
  rows,
  columns,
  columnGrouping,
  pinnedColumns,
  totalValues,
  secondaryTableColumns,
  secondaryTableData,
  isSecondaryTable = false
}: {
  rows: any[];
  columns: Column[];
  columnGrouping?: ColumnGrouping[];
  pinnedColumns?: string[];
  totalValues?: TotalValue[];
  secondaryTableColumns?: Column[];
  isSecondaryTable?: boolean;
  secondaryTableData?: { id: number; expanded: boolean; rows: any[] }[];
}) => {
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [data, setData] = React.useState<any[]>(rows);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const renderCellWithRowData = (
    renderCell: (value: any) => JSX.Element,
    row: any
  ) => {
    return renderCell(row);
  };

  const calculateLeftOffset = (columnIndex: number) => {
    let left = 0;
    for (let i = 0; i < columnIndex; i++) {
      const column = columns[i];
      if (pinnedColumns && pinnedColumns.includes(column.field)) {
        left += column.width || 100;
      }
    }
    return left;
  };

  const getNestedProperty = (obj: any, path: string) => {
    return path.split(".").reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  };

  const sort = (array: any[], field: string, direction: "asc" | "desc") => {
    return array.sort((a, b) => {
      const aValue = getNestedProperty(a, field);
      const bValue = getNestedProperty(b, field);

      if (aValue === null) return direction === "asc" ? 1 : -1;
      if (bValue === null) return direction === "asc" ? -1 : 1;

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSortClick = (field: string) => () => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
    setData(sort(data, field, isAsc ? "desc" : "asc"));
  };

  React.useEffect(() => {
    setData(rows);
  }, [rows]);

  return (
    <Paper
      sx={{
        width: "100%",
        borderBottomLeftRadius: isSecondaryTable ? "0px" : "8px",
        borderBottomRightRadius: isSecondaryTable ? "0px" : "8px",
        boxShadow: isSecondaryTable
          ? "none"
          : "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderBottom: isSecondaryTable ? "2px solid #B3B3B3" : "none"
      }}
    >
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {columnGrouping && (
              <TableRow>
                {columnGrouping.map((col, index) => {
                  return (
                    <TableCell
                      key={index}
                      colSpan={col.columns ? col.columns.length : 1}
                      rowSpan={col.columns.length == 0 ? 2 : 1}
                      align={col.align ? col.align : "center"}
                      className={col.pinned ? "pinned-header" : "header"}
                      style={{
                        position: "sticky",
                        top: 0,
                        minWidth: col.width,
                        backgroundColor: "#f3f4f7",
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#1E293B",
                        borderLeft: col.border ? col.border : "none",
                        borderBottom:
                          col.columns.length == 0
                            ? "1px solid #B3B3B3"
                            : "none",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        ...(col.pinned && {
                          position: "sticky",
                          left: 0,
                          zIndex: 1000,
                          borderRight: "1px solid #B3B3B3"
                        })
                      }}
                    >
                      {col.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            <TableRow>
              {columnGrouping &&
                columnGrouping.map((colGrp) =>
                  colGrp.columns.map((col, index) => {
                    const column = columns.find((c) => c.field === col)!;
                    const isPinned =
                      pinnedColumns && pinnedColumns.includes(column.field);
                    const leftOffset = calculateLeftOffset(index);
                    return (
                      <TableCell
                        key={column.field}
                        align={column.align || "center"}
                        className={isPinned ? "pinned-header" : "header"}
                        style={{
                          position: "sticky",
                          top: "48px",
                          minWidth: column.width,
                          backgroundColor: "#f3f4f7",
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#1E293B",
                          borderLeft: column.borderLeft || "none",
                          borderRight: column.borderRight || "none",
                          borderBottom: "1px solid #B3B3B3",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          ...(isPinned && {
                            position: "sticky",
                            left: leftOffset,
                            zIndex: 1000,
                            borderRight: "1px solid #B3B3B3"
                          })
                        }}
                      >
                        {column.renderHeader
                          ? column.renderHeader()
                          : column.headerName}
                      </TableCell>
                    );
                  })
                )}
              {!columnGrouping &&
                columns.map((column, index) => {
                  const isPinned =
                    pinnedColumns && pinnedColumns.includes(column.field);
                  const leftOffset = calculateLeftOffset(index);
                  return (
                    <TableCell
                      key={column.field}
                      align={column.align || "center"}
                      className={isPinned ? "pinned-header" : "header"}
                      style={{
                        minWidth: column.width,
                        backgroundColor: "#f3f4f7",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#1E293B",
                        borderLeft: column.borderLeft || "none",
                        borderRight: column.borderRight || "none",
                        borderBottom: "1px solid #B3B3B3",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        ...(isPinned && {
                          position: "sticky",
                          left: leftOffset,
                          zIndex: 1000,
                          borderRight: "1px solid #B3B3B3"
                        }),
                        ...(isSecondaryTable && {
                          padding: "5px 12px",
                          zIndex: 1
                        })
                      }}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={true}
                          direction={orderBy === column.field ? order : "asc"}
                          onClick={handleSortClick(column.field)}
                        >
                          {column.headerName}
                        </TableSortLabel>
                      ) : (
                        <>{column.headerName}</>
                      )}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow tabIndex={-1} key={index}>
                    {columns.map((column, index) => {
                      const value = row[column.field];
                      const cellContent = column.renderCell
                        ? renderCellWithRowData(column.renderCell, row)
                        : value;
                      const isPinned =
                        pinnedColumns && pinnedColumns.includes(column.field);
                      const leftOffset = calculateLeftOffset(index);
                      return (
                        <TableCell
                          key={column.field}
                          align={column.align || "center"}
                          className={isPinned ? "pinned-body" : "body"}
                          style={{
                            width: column.width,
                            borderLeft:
                              column.borderLeft || "1px solid #E5E5E5",
                            borderBottom: "1px solid #E5E5E5",
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            ...(isPinned && {
                              position: "sticky",
                              left: leftOffset,
                              zIndex: 999,
                              backgroundColor: "#f3f4f7",
                              borderRight: "1px solid #B3B3B3"
                            })
                          }}
                          onClick={() => column.onClick && column.onClick(row)}
                        >
                          {cellContent}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {secondaryTableData &&
                    secondaryTableColumns &&
                    secondaryTableData.find(
                      (std) => std.id === index && std.expanded
                    ) && (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          style={{ padding: 0 }}
                        >
                          <DataGridTable
                            rows={
                              secondaryTableData.find(
                                (std) => std.id === index
                              )!.rows
                            }
                            columns={secondaryTableColumns}
                            isSecondaryTable
                          />
                        </TableCell>
                      </TableRow>
                    )}
                </React.Fragment>
              );
            })}
            {totalValues && (
              <TableRow>
                {columns.map((column, index) => {
                  const totalValue = totalValues.find(
                    (tv) => tv.field === column.field
                  );
                  const isPinned =
                    pinnedColumns && pinnedColumns.includes(column.field);
                  const leftOffset = calculateLeftOffset(index);
                  return (
                    <TableCell
                      key={column.field}
                      align="center"
                      className={isPinned ? "pinned-body total" : "body total"}
                      style={{
                        backgroundColor: "#f3f4f7",
                        borderLeft: "1px solid #E5E5E5",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        borderBottomLeftRadius: index == 0 ? "8px" : "0",
                        borderBottomRightRadius:
                          index == columns.length - 1 ? "8px" : "0",
                        ...(isPinned && {
                          position: "sticky",
                          left: leftOffset,
                          zIndex: 1000 + columns.length - index,
                          backgroundColor: "#f3f4f7"
                        })
                      }}
                    >
                      {totalValue ? (
                        totalValue.renderCell(rows)
                      ) : (
                        <span style={{ userSelect: "none" }}>&nbsp;</span>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
